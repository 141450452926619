<!--
Tabella settimanale che mostra i viaggi richiesti e quelli pianificati
Il click sulle celle apre il dettaglio per operare la pianificazione

    //TODO UPDATE ON WINDOW FOCUS
-->
<template>
  <v-container fluid class="pa-1">
    <v-simple-table class="mj_simple_table mj_table_border_right">
      <thead class="smaller lighten-3 grey" v-if="hideHeader == undefined">
      <th class="text-left pl-4">Prodotto</th>
      <th v-for="day in daysHeader" class="day_th" :key="day">{{ day }}</th>
      <th class="tot_th">TOT</th>
      </thead>
      <tbody>
      <!--      LOADING TRs-->
      <tr v-if="loadingProductTypes > 0">
        <td colspan="20" class="loader_td">
          <v-progress-linear indeterminate></v-progress-linear>
        </td>
      </tr>
      <tr v-if="loadingShipmentRequests > 0">
        <td colspan="20" class="loader_td">
          <v-progress-linear indeterminate></v-progress-linear>
        </td>
      </tr>
      <tr v-if="loadingShipmentsCountDayIndexed > 0">
        <td colspan="20" class="loader_td">
          <v-progress-linear indeterminate></v-progress-linear>
        </td>
      </tr>

      <!--      BODY ROWS-->
      <template v-for="(productType,index) in productTypes">
        <tr :key="index">
          <td :style="{color:`#${productType.color_hex}`}">{{ productType.cod }}</td>
          <td v-for="(day,dayIndex) in daysHeader"
              :class="[isOverPlanned(productType.id,dayIndex) ? 'over_planned': '',
                       isFullPlanned(productType.id,dayIndex) ? 'full_planned': '',
                       isZeroOnZero(productType.id,dayIndex) ? 'zero_request_zero_planned': '',
                       'request_vs_planned_td', 'clickable_cell']"
              :key="day"
              @click="openPlanningShipmentDetail(productType.id,dayIndex)">
            {{ getShipmentCountFor(productType.id, dayIndex) }} /
            {{ getShipmentRequestCountFor(productType.id, dayIndex) }}
          </td>
          <td class="tot_td">
            {{ shipmentSumFor(productType.id) }} /
            {{ shipmentRequestSumFor(productType.id) }}
          </td>
        </tr>
        <tr :key="index+'_remaining_tr'" class="remaining_tr">
          <td class="text--disabled" style="font-size: 10px;">da pianificare</td>
          <td v-for="(day,dayIndex) in daysHeader"
              :class="[isZeroOnZero(productType.id,dayIndex) ? 'zero_request_zero_planned': '','remaining_td']"
              :key="day">
            {{ getShipmentRequestCountFor(productType.id, dayIndex) - getShipmentCountFor(productType.id, dayIndex) }}
          </td>
          <td class="remaining_tot_td">
            {{
              shipmentRequestSumFor(productType.id) -
              shipmentSumFor(productType.id)
            }}
          </td>
        </tr>
        <tr :key="index+'_empty_tr'" class="empty_tr">
          <td colspan="100"></td>
        </tr>
      </template>

      </tbody>
    </v-simple-table>
    <div class="text--disabled smaller text-right mr-1 mt-1">Impianto {{ productionPlant.cod }}</div>

  </v-container>

</template>

<script>
import ShipmentRequestMixin from "@/mixins/entities/ShipmentRequestMixin";
import ProductTypeMixin from "@/mixins/entities/ProductTypeMixin";
import ShipmentMixin from "@/mixins/entities/ShipmentMixin";
import mj_array_utils from "@/misc/mj_array_utils";

export default {
  name: "ShipmentRequestVsPlanning",

  mixins: [ShipmentRequestMixin, ProductTypeMixin, ShipmentMixin],
  props: {
    hideHeader: {},
    productionPlant: {type: Object},
    weekId: {type: String},

  },

  mounted() {
    this.loadProductTypesForProductionPlant(this.productionPlant.id)
    this.loadTableData();
  },
  data: function () {
    return {
      daysHeader: ['LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'],
      showShipmentPlanningApproveDialog: false,
    }
  },
  methods: {
    loadTableData() {
      this.loadShipmentRequests(this.productionPlant.id, this.weekId);
      this.loadShipmentsCountDayIndexedFor(this.productionPlant.id, this.weekId);
    },
    getShipmentRequestCountFor(productTypeId, dayIndex) {
      if (this.shipmentRequests) {
        const productTypeWeeklyRequest = this.shipmentRequests.productTypeWeeklyRequests.find((productTypeWeeklyRequest) => productTypeWeeklyRequest.productTypeId === productTypeId);
        return productTypeWeeklyRequest.daysIndexedShipmentRequestCount[dayIndex];
      } else return '-';
    },
    getShipmentCountFor(productTypeId, dayIndex) {
      if (this.shipmentsCountDayIndexed) {
        const productTypeWeeklyShipment = this.shipmentsCountDayIndexed.productTypeWeeklyShipments.find((productTypeWeeklyShipment) => productTypeWeeklyShipment.productTypeId === productTypeId);
        return productTypeWeeklyShipment.daysIndexedShipments[dayIndex];
      } else return '-'
    },
    shipmentRequestSumFor(productTypeId) {
      if (this.shipmentRequests) {
        const productTypeWeeklyRequest = this.shipmentRequests.productTypeWeeklyRequests.find((productTypeWeeklyRequest) => productTypeWeeklyRequest.productTypeId === productTypeId);
        return mj_array_utils.sumReduce(productTypeWeeklyRequest.daysIndexedShipmentRequestCount);
      } else return '-';

    },
    shipmentSumFor(productTypeId) {
      if (this.shipmentsCountDayIndexed) {
        const productTypeWeeklyShipment = this.shipmentsCountDayIndexed.productTypeWeeklyShipments.find((productTypeWeeklyShipment) => productTypeWeeklyShipment.productTypeId === productTypeId);
        return mj_array_utils.sumReduce(productTypeWeeklyShipment.daysIndexedShipments);
      } else return '-';

    },


    isOverPlanned(productTypeId, dayIndex) {
      return this.getShipmentRequestCountFor(productTypeId, dayIndex) < this.getShipmentCountFor(productTypeId, dayIndex);
    },
    isFullPlanned(productTypeId, dayIndex) {
      const shipmentRequestCountFor = this.getShipmentRequestCountFor(productTypeId, dayIndex);
      return shipmentRequestCountFor == this.getShipmentCountFor(productTypeId, dayIndex) && shipmentRequestCountFor !== 0;
    },
    isZeroOnZero(productTypeId, dayIndex) {
      return this.getShipmentRequestCountFor(productTypeId, dayIndex) == 0 &&
          this.getShipmentCountFor(productTypeId, dayIndex) == 0;
    },
    openPlanningShipmentDetail(productTypeId, dayIndex) {
      let routeData = this.$router.resolve({
        path: '/planning/shipment_detail',
        query: {
          product_type_id: productTypeId,
          day_index: dayIndex,
          production_plant_id: this.productionPlant.id,
          week_id: this.weekId,
          //i dati a seguire vengono inviati a solo scopo di visualizzazione senza fare ulteriori get.
          //non dovrebbero fare parte della query string, ma si sceglie per il momento questa strada per velocizzare la modifica.
          request_count:this.getShipmentRequestCountFor(productTypeId, dayIndex),
          shipment_count: this.getShipmentCountFor(productTypeId, dayIndex)
        },

      });
      const top = 100 + 5 * dayIndex;
      const left = 100 + 5 * dayIndex;
      window.open(routeData.href, `_blank`, `location=yes,height=800,width=799,top=${top},left=${left},scrollbars=yes,status=yes`);
    },

  },

  watch: {
    weekId: function () {
      this.loadTableData();
    },
  }

}
</script>

<style scoped>

.empty_tr td {
  height: 10px !important;
  background-color: #eee !important;

}

.empty_tr:last-child td {
  display: none
}

.remaining_tr td {
  height: 10px !important;
  border-bottom: solid 1px #aaa !important;

}

.over_planned {
  background-color: purple;
  color: white;
}

.full_planned {
  background-color: green;
  color: white;
}

.zero_request_zero_planned {
  color: #dddddd;
}

.request_vs_planned_td,
.remaining_td {
  text-align: center;
}

.day_th .tot_th {
  text-align: center
}

.tot_th {
  font-weight: bold
}

.tot_td, .remaining_tot_td {
  font-weight: bold;
  text-align: center
}

.tot_td:nth-of-type(even),
.remaining_tot_td:nth-of-type(even) {
  background-color: rgba(0, 128, 128, .06);
}

.tot_td:nth-of-type(odd),
.remaining_tot_td:nth-of-type(odd) {
  background-color: rgba(0, 128, 128, .03);
}

</style>