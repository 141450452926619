<template>
  <v-container fluid>
    <mj-soft-title class="mb-5 ml-3" icon="mdi-calendar-end"
                   :title="`Dettaglio ${this.selectedProductionPlant ? this.selectedProductionPlant.cod : ' - '}, ${ this.selectedProductType ? this.selectedProductType.cod : ' - ' }, ${dayOnQueryString} (${shipment_count}/${requestCountOnQueryString})`">
    </mj-soft-title>
    <v-card>
      <v-card-title class="overline text_on_primary_background primary py-1">
        <v-icon class="mr-2 text_on_primary_background">mdi-filter-outline</v-icon>
        Vincoli
      </v-card-title>
      <v-card-text>
        <TariffFilters :selected-values.sync="selectedTariffFilters"></TariffFilters>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title class="overline text_on_primary_background primary py-1">
        <v-icon class="text_on_primary_background mr-2">mdi-lightbulb-on-outline</v-icon>
        Suggerimenti da Tariffario
      </v-card-title>
      <v-card-text class="pa-0">
        <ShipmentTariffsOptimizer v-model="selectedShipmentTariff" :tariff-filters="selectedTariffFilters"/>
      </v-card-text>
    </v-card>

    <v-card class="mt-6">
      <v-card-title class="overline text_on_primary_background primary py-1">
        <v-icon class="text_on_primary_background mr-2">mdi-calendar-end</v-icon>
        Scelta per Prossima Pianificazione
      </v-card-title>
      <v-card-text>
        <ShipmentInput :selectedShipmentTariff="selectedShipmentTariff"
                       :week-id="$route.query.week_id"
                       :day-index="dayIndex"
                       :production-plant-id="$route.query.production_plant_id"
                       @shipmentPlanned="shipmentPlanned"/>
      </v-card-text>
    </v-card>


    <v-card class="mt-6">
      <v-card-title class="overline text_on_primary_background primary py-1">
        <v-icon class="text_on_primary_background mr-2">mdi-calendar-check</v-icon>
        Pianificati - {{ this.selectedProductionPlant ? this.selectedProductionPlant.cod : ' - ' }},
        {{ this.selectedProductType ? this.selectedProductType.cod : ' - ' }}, {{ dayOnQueryString }}
        <v-spacer>
        </v-spacer>
        <v-btn icon class="text_on_primary_background" @click="$refs.shipmentPlannedTable.refresh()"><v-icon>mdi-refresh</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <ShipmentPlanned :productType="selectedProductType"
                         :productionPlant="selectedProductionPlant"
                         :dayIndex="this.$route.query.day_index"
                         :weekId="this.$route.query.week_id"
                         ref="shipmentPlannedTable"
                         @updateShipmentPlanned="(shipmentsPlanned)=>this.shipment_count = shipmentsPlanned.length"
        ></ShipmentPlanned>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import MjSoftTitle from "@/components/MjSoftTitle";
import ShipmentTariffsOptimizer from "@/components/custom/ShipmentTariffsOptimizer";
import ProductionPlantMixin from "@/mixins/entities/ProductionPlantMixin";
import moment from "moment";
import ProductTypeMixin from "@/mixins/entities/ProductTypeMixin";
import TariffFilters from "@/components/custom/TariffFilters";
import ShipmentInput from "@/components/custom/ShipmentInput";
import ShipmentPlanned from "@/components/custom/ShipmentPlanned";
import ShipmentRequestMixin from "@/mixins/entities/ShipmentRequestMixin";
import ReactivityMixin from "@/mixins/ReactivityMixin";


export default {
  name: "PlanningShipments",
  components: {ShipmentInput, TariffFilters, ShipmentTariffsOptimizer, MjSoftTitle, ShipmentPlanned},
  mixins: [ProductionPlantMixin, ProductTypeMixin, ShipmentRequestMixin, ReactivityMixin],
  computed:
      {
        shipmentCountOnQueryString(){return this.$route.query.shipment_count},
        requestCountOnQueryString(){return this.$route.query.request_count},

        dayOnQueryString() {
          return moment().locale('IT').weekday(this.$route.query.day_index).format('ddd');
        },
        dayIndex() {
          return parseInt(this.$route.query.day_index);
        },
        currentShipmentRequest() {
          return this.shipmentRequests ? this.shipmentRequests.productTypeWeeklyRequests : null;
        },
        currentShipmentRequestForcedPackageTypeId() {
          //indice zero su productTypeWeeklyRequests in quanto è l'unico elemento
          return this.shipmentRequests ? this.shipmentRequests?.productTypeWeeklyRequests[0]?.daysIndexedPackagingTypeId[this.dayIndex] : null;
        }
      },
  created() {
    this.loadSelectedProductionPlant(this.$route.query.production_plant_id);
    this.loadSelectedProductType(this.$route.query.product_type_id);
    this.loadShipmentRequests(this.$route.query.production_plant_id, this.$route.query.week_id, this.$route.query.product_type_id);

  },

  data: function () {
    return {
      selectedTariffFilters: {productType:{id:this.$route.query.product_type_id}},
      selectedShipmentTariff: null,
      shipment_count: 0,
    }
  },
  methods: {
    shipmentPlanned() {
      this.$refs.shipmentPlannedTable.refresh();
    }
  },
  watch: {
    shipmentRequests: {
      deep: true,
      handler: function () {
        if (this.currentShipmentRequestForcedPackageTypeId != null) {
          const selectedTariffFiltersClone = Object.assign({}, this.selectedShipmentTariff);
          selectedTariffFiltersClone['packagingType'] = {
            id: this.currentShipmentRequestForcedPackageTypeId,
            disabled: true
          };
          this.updateObject('selectedTariffFilters', selectedTariffFiltersClone);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>