<template>
  <v-dialog v-model="dialogShow">
    <v-card>
      <v-card-title>
        <v-row align="start">
          <v-col align-self="center" class="text-center pr-1" cols="auto">
            <v-img v-bind:src="(qrImageUrl)" height="64" width="64" contain alt="`qr code ${shipmentQrCode}`"></v-img>
          </v-col>
          <v-col>
            <div class="mb-0 pb-0 second_font text--secondary overline">
              Dettagli Viaggio
            </div>
            <div class="pt-0 second_font text--secondary  second_font font-weight-bold">{{ shipmentQrCode }}
              <v-progress-linear indeterminate v-if="loadingShipmentData > 0" class="mb-6"></v-progress-linear>
            </div>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn @click="dialogShow=false" icon color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="Object.keys(this.shipment).length > 0">
        <!--        TABLE SHIPMENT INFO-->
        <v-simple-table class="mj_simple_table mx-auto mt-2">
          <tbody>
          <tr v-if="loadingShipmentData > 0">
            <td colspan="20" class="loader_td">
              <v-progress-linear indeterminate></v-progress-linear>
            </td>
          </tr>

          <tr>
            <td class="text--secondary table_label">
              <v-icon class="mr-2">mdi-alpha-a-circle-outline</v-icon>
              Codice Anthea
            </td>
            <td>{{ shipment["antheaCode"] ? shipment["antheaCode"] : 'Non definito' }}

            </td>
            <td class="text-right mr-1">
                  <v-btn
                         small color="primary" @click="copyToClipboardAntheaCode">copy
                  </v-btn>

              <div v-show="copyToClipboardFeedbackShow" class="mx-auto">
                {{copyToClipboardFeedbackText}}
              </div>

            </td>
          </tr>

          <tr>
            <td class="text--secondary table_label">
              <v-icon class="mr-2">mdi-archive-outline</v-icon>
              Prodotto
            </td>
            <td colspan="2">{{ shipment["productTypeCod"] }}</td>
          </tr>

          <tr>
            <td class="text--secondary table_label">
              <v-icon class="mr-2">mdi-factory</v-icon>
              Produttore
            </td>
            <td colspan="2">{{ shipment["productionPlantCod"] }} - {{ shipment["productionPlantName"] }}</td>
          </tr>
          <tr>
            <td class="text--secondary table_label">
              <v-icon class="mr-2">mdi-truck-outline</v-icon>
              Trasportatore
            </td>
            <td colspan="2">{{ shipment["carrierName"] }}</td>
          </tr>
          <tr>
            <td class="text--secondary table_label">
              <v-icon class="mr-2">mdi-flag-outline</v-icon>
              Destino
            </td>
            <td colspan="2">{{ shipment["destinationPlantCod"] }} - {{ shipment["destinationPlantName"] }}</td>
          </tr>
          <tr>
            <td class="text--secondary table_label">
              <v-icon class="mr-2">mdi-account-tie</v-icon>
              Intermediario
            </td>
            <td colspan="2">{{ shipment["intermediaryCod"] }} - {{ shipment["intermediaryName"] }}</td>
          </tr>
          <tr>
            <td>
              <v-icon class="mr-2">mdi-format-list-checkbox</v-icon>
              Storico stati
            </td>
            <td class="pl-0" colspan="2">
              <ShipmentStatusEvents :shipmentQrCode="shipment['shipmentQrCode']"/>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-alert class="mt-6" dense type="info" outlined v-if="shipment['internal_note']">{{ shipment["internal_note"] }}</v-alert>
      </v-card-text>
      <!--      SHIPMENT STATUS AND AVAILABLE ACTIONS-->
      <v-card-actions v-if="Object.keys(this.shipment).length > 0">
        <v-layout row align-baseline class="mb-4  mr-0">

          <v-btn class="ml-5" fab small v-if="$store.getters.isAdmin"
                 @click="$router.push({path:`/form_shipment/${shipment.id}`})">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <MjEnumIcon x-large load-items-url="/api/shipment_statuses" :value="shipment.status"></MjEnumIcon>
          <div v-if="availableButtons && availableButtons.length > 0">
            <v-icon x-large class="mx-5 grey--text text--lighten-2">mdi-transfer-right</v-icon>
            <v-btn v-for="(btn,index) in availableButtons" class="mr-2" large :color="btn.color" :key="index"
                   @click="changeShipmentStatus(btn.newStatus)" :loading="changingShipmentStatus>0">{{
                btn.text
              }}
              <v-icon right>{{ btn.icon }}</v-icon>
            </v-btn>
          </div>
          <!--  NO ACTIONS AVAILABLE ALERT-->
          <div v-else class="ml-4">
            <v-alert v-if="loadingShipmentData == 0" outlined type="warning" dense>Nessun Cambio Stato Disponibile
            </v-alert>
          </div>
        </v-layout>
      </v-card-actions>
      <!--  NO SHIPMENT FOUND-->
      <v-card-text v-if="loadingShipmentData == 0 && (Object.keys(this.shipment).length == 0 || this.shipment == null)">
        <v-alert type="error" outlined>Nessun Viaggio trovato con il codice: <span
            class="font-weight-bold">{{ shipmentQrCode }}</span></v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MjEnumIcon from "@/components/MjEnumIcon";
import mj_axios from "@/mj_axios";
import ShipmentStatusEvents from "@/components/custom/ShipmentStatusEvents";
import ReactivityMixin from "@/mixins/ReactivityMixin";
import mj_config from "@/mj_config";
import ShipmentStatusMjEnumMixin from "@/mixins/entities/ShipmentStatusMjEnumMixin";

export default {
  name: "ShipmentsDialog",
  components: {MjEnumIcon, ShipmentStatusEvents},
  mixins: [ReactivityMixin, ShipmentStatusMjEnumMixin],
  props: {
    shipmentQrCode: null,
    value: {default: () => false}
  },
  mounted() {
    this.loadShipmentStatusMjEnum();
    this.loadShipmentStatusActionMap();
  },
  computed: {
    qrImageUrl: function () {
      return `${mj_config.getBaseUrl()}/api/qr/${encodeURIComponent(this.shipmentQrCode)}`;
    }
    ,
    availableButtons: function () {
      return this.actionsMap[this.shipment.status];
    }
  },
  data: function () {
    return {
      dialogShow: this.value,
      loadingShipmentData: 0,
      changingShipmentStatus: 0,
      loadingActionMap: 0,
      shipmentStatusMjEnum: {},
      shipment: {},
      actionsMap: {},
      copyToClipboardFeedbackShow: false,
      copyToClipboardFeedbackText: ''
    }
  },
  methods: {

    loadShipmentStatusActionMap: function () {
      this.loadingActionMap++;
      const axios_promise = mj_axios.get('/api/shipment_statuses_action_map');
      axios_promise.then(response => {
        const actionsMap = response.data;
        Object.keys(actionsMap).forEach((key) => {
          this.$set(this.actionsMap, key, actionsMap[key]);
        });

      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento Action Map, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingActionMap--;
      });
      return axios_promise;
    },
    loadShipmentData: function () {
      this.resetObject("shipment");
      this.loadingShipmentData++;
      const axios_promise = mj_axios.get(`/api/monitor/shipment/${encodeURIComponent(this.shipmentQrCode)}`);
      axios_promise.then(response => {
        const shipment = response.data.data;
        this.updateObject("shipment", shipment);
        setTimeout(()=>this.copyToClipboardAntheaCode(),200); //set timeout per evitare che problemi su copyToCliboard vengano catturati su questo catch
      }).catch((error) => {

        if (error?.response?.status != 404) {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        } else {
          this.resetObject("shipment");
        }

      }).then(() => {
        this.loadingShipmentData--;
      });
      return axios_promise;
    },
    changeShipmentStatus: function (newStatus) {
      this.changingShipmentStatus++;
      const params = {oldStatus: this.shipment.status, newStatus: newStatus};
      const axios_promise = mj_axios.post(`/api/shipments/${encodeURIComponent(this.shipmentQrCode)}/actions/change`, params);
      axios_promise.then(() => {
        this.loadShipmentData();
        this.$emit("shipmentStatusChanged");
      }).catch((e) => {
        const message = e && e.response && e.response.data && e.response.data.message || ''
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di cambio stato viaggio,${message} Se il problema persiste contattare l'assistenza tecnica.`,
          visible: true,
        });
      }).then(() => {
        this.changingShipmentStatus--;
      });
      return axios_promise;
    },
    copyToClipboardAntheaCode() {

      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.shipment["antheaCode"])
            .then(() => this.copyToClipboardFeedbackText = 'Copiato nella Clipboard')
            .catch(() => this.copyToClipboardFeedbackText = '!ERRORE! di Copia nella Clipboard');
      }
      else{
        this.copyToClipboardFeedbackText = 'Copia nella Clipboard non supportata, contatta il supporto tecnico.';
      }
      this.copyToClipboardFeedbackShow = true;
      setTimeout(()=>{this.copyToClipboardFeedbackShow = false;},2000);

    }
  },
  watch:
      {
        'value': function (val) {
          this.dialogShow = val;
        },
        'dialogShow': function (val) {
          this.$emit('input', val);
          if (val) this.loadShipmentData();
        },
      }
}
</script>

<style scoped>
.table_label {
  width: 160px !important;
}
</style>