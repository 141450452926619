<template>
  <v-simple-table class="mj_simple_table">
    <thead>
    <tr>
      <th>Trasportatore</th>
      <th>Mezzo</th>
      <th>Imballo</th>
      <th>Imp. Dest.</th>
      <th>Intermediario</th>
      <th>Costo</th>
      <th>Stato</th>
      <th>Rev</th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="loadingShipments > 0">
      <td colspan="20" class="loader_td">
        <v-progress-linear indeterminate></v-progress-linear>
      </td>
    </tr>
    <tr v-for="(shipmentPlanned,index) in shipmentsPlanned" :key="index" class="clickable_cell"
        @click="openShipment(shipmentPlanned)">
      <td>{{ shipmentPlanned?.carrier?.cod }}</td>
      <td>{{ shipmentPlanned?.vehicleType?.cod }}</td>
      <td>{{ shipmentPlanned?.packagingType?.cod }}</td>

      <td>{{ shipmentPlanned?.destinationPlant?.cod }}</td>
      <td>{{ shipmentPlanned.intermediary ? shipmentPlanned.intermediary.cod : ' - ' }}</td>
      <td>{{ mj_string_utils.moneyFormat(shipmentPlanned?.price) }}</td>
      <td>
        <MjEnumIcon :value="shipmentPlanned.status.value" :items-prop="shipmentStatusMjEnum"></MjEnumIcon>
      </td>
      <td>{{ shipmentPlanned.revision }}</td>
      <td>
        <v-btn icon x-small color="danger"
               :loading="deletingShipmentsLoaders[shipmentPlanned.id] > 0"
               @click.stop="removeShipmentPlanned(shipmentPlanned)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import mj_string_utils from "@/misc/mj_string_utils";
import ShipmentMixin from "@/mixins/entities/ShipmentMixin";
import mj_date_utils from "@/misc/mj_date_utils";
import MjEnumIcon from "@/components/MjEnumIcon";
import ReactivityMixin from "@/mixins/ReactivityMixin";
import ShipmentStatusMjEnumMixin from "@/mixins/entities/ShipmentStatusMjEnumMixin";


export default {
  name: "ShipmentPlanned",
  components: {MjEnumIcon},
  mixins: [ShipmentMixin, ReactivityMixin, ShipmentStatusMjEnumMixin],
  props: {
    productType: {},
    productionPlant: {},
    dayIndex: {},
    weekId: {},
  },
  mounted() {
    this.refresh();
    this.loadShipmentStatusMjEnum();
  },
  data:
      function () {
        return {
          mj_string_utils: mj_string_utils,
          shipmentsPlanned: [],
          deletingShipmentsLoaders: {}
        }
      },
  methods: {
    refresh() {
      if (this.productionPlant && this.productType) {
        this.loadShipments(mj_date_utils.getMomentDate(this.weekId, this.dayIndex).format('YYYY-MM-DD'), this.productionPlant.id, this.productType.id)
            .then(() => this.shipmentsPlanned = this.shipments);
      }
    },
    removeShipmentPlanned(shipmentPlanned) {

      this.$set(this.deletingShipmentsLoaders, shipmentPlanned.id, 1);

      this.deleteShipment(shipmentPlanned.id).then(
          () => {
            this.$delete(this.shipmentsPlanned, this.shipmentsPlanned.indexOf(shipmentPlanned));
            this.$set(this.deletingShipmentsLoaders, shipmentPlanned.id, 0)
          }
      )
          .then(() => this.$set(this.deletingShipmentsLoaders, shipmentPlanned.id, 0))

    },


    openShipment(shipmentPlanned) {
      let routeData = this.$router.resolve({
        path: `/form_shipment/${shipmentPlanned.id}`,

      });
      window.open(routeData.href, `_blank`);
    },
  }
  ,
  watch: {
    productionPlant: {
      deep: true,
      handler: function () {
        this.refresh();
      }
    }
    ,
    productType: {
      deep: true,
      handler: function () {
        this.refresh();
      }
    },
    shipmentsPlanned:{
      deep: true,
      handler(){
        this.$emit("updateShipmentPlanned",this.shipmentsPlanned)
      }
    }
  }

}
</script>

<style scoped>

</style>